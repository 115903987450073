function Button(props: {
  label: string;
  onClick?: () => void;
  secondary?: boolean;
  class?: string;
}) {
  return (
    <button
      className={`cursor-pointer p-4 border-none flex flex-row items-center justify-center whitespace-nowrap rounded-[67px] transition-all duration-300 ${
        props.secondary
          ? 'bg-default text-primary hover:bg-primary hover:text-default'
          : 'bg-primary text-default hover:bg-default hover:text-primary'
      } ${props.class}`}
      onClick={props.onClick}
    >
      <div className="font-bold text-[16px] text-inherit">{props.label}</div>
    </button>
  );
}

export default Button;
