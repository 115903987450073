import { ThemeProvider } from '@material-tailwind/react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';

// @ts-ignore
import yupLocalePL from 'yup-locale-pl';

yup.setLocale(yupLocalePL);

import AppWrapper from '@/components/AppWrapper';
import { MetaPixel } from '@/components/seo/MetaPixel';
import '@/globals.css';
import { theme } from '@/materialTheme';
import { queryClient } from '@/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';

type CustomPage = NextPage & {
  requiresAuth?: boolean;
  redirectUnauthenticatedTo?: string;
};
interface CustomAppProps extends Omit<AppProps, 'Component'> {
  Component: CustomPage;
}

function MyApp({ Component, pageProps }: CustomAppProps) {
  return (
    <ThemeProvider value={theme}>
      <QueryClientProvider client={queryClient}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          {/* <AnalyticsScripts /> */}
        </Head>
        <MetaPixel />
        <AppWrapper>
          <Component {...pageProps} />
          <CookieConsent
            location="bottom"
            buttonText="Rozumiem"
            cookieName="LikepageCookie"
            style={{ background: '#2B373B' }}
            buttonStyle={{
              backgroundColor: '#0057ff',
              color: '#fff',
              fontSize: '14px',
            }}
            expires={150}
          >
            Ta strona korzysta z ciasteczek (cookies), dzięki którym nasz serwis
            może działać lepiej.
          </CookieConsent>
        </AppWrapper>
        <ToastContainer />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default MyApp;
