import { ReactNode } from 'react';

import Footer from './Footer';
import Navbar from './Navbar';
import { defaultStyles } from '@/helper/defaultStyles';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  return (
    <>
      <div className={`${defaultStyles.paddingBig} !pb-0`}>
        <Navbar />
      </div>
      <div className="mx-auto">{children}</div>
      <Footer />
    </>
  );
};

export default AppWrapper;
