import { defaultStyles } from '@/helper/defaultStyles';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../Button';
import CustomTextInput from '../form/CustomTextInput';
import CustomTextarea from '../form/CustomTextarea';
import CustomCheckbox from '../form/CustomCheckbox';

function ContactCard(props: { onClick: () => void }) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState({
    email: '',
    name: '',
    lastname: '',
    phone: '',
    url: '',
    message: '',
    agreements: false,
  });

  const onSubmit = () => {
    if (
      form.name == '' ||
      form.email == '' ||
      form.lastname == '' ||
      form.phone == '' ||
      form.url == '' ||
      form.message == '' ||
      form.agreements == false
    ) {
      setErrorMessage('Uzupełnij wszystkie pola formularza');
      return;
    } else {
      setErrorMessage(null);
    }

    toast.promise(axios.post('https://api.likepage.eu/contact/', form), {
      pending: 'Wysyłanie wiadomości...',
      success: 'Wysłano wiadomość',
      error: 'Wystąpił błąd przy wysyłaniu',
    });
  };

  return (
    <div
      className={`${defaultStyles.padding} relative flex flex-col justify-center items-center py-4 rounded-[31px] w-[90%]`}
    >
      <div
        className="text-default absolute top-10 right-10 lg:top-4 lg:right-4 z-50 cursor-pointer hover:text-primary transition-all duration-300"
        onClick={props.onClick}
      >
        ✖
      </div>
      <div className="w-[90%] lg:w-[60%] backdrop-blur-lg p-8 rounded-[41px] flex flex-col gap-2 lg:gap-8 items-center justify-center bg-[#060B1099]">
        <div className="w-full flex flex-col gap-2">
          <div className="font-extrabold text-[25px] lg:text-[45px] text-default my-2 text-center">
            Skontaktuj się z nami
          </div>
          <CustomTextInput
            label="Adres E-mail"
            placeholder="mail@mail.com"
            value={form.email}
            onChange={(val) => setForm({ ...form, email: val })}
          />
          <CustomTextInput
            label="Imię"
            placeholder="Jan"
            value={form.name}
            onChange={(val) => setForm({ ...form, name: val })}
          />
          <CustomTextInput
            label="Nazwisko"
            placeholder="Kowalski"
            value={form.lastname}
            onChange={(val) => setForm({ ...form, lastname: val })}
          />
          <CustomTextInput
            label="Numer telefonu"
            placeholder="+48000000000"
            value={form.phone}
            onChange={(val) => setForm({ ...form, phone: val })}
          />
          <CustomTextInput
            label="Link do strony"
            placeholder="https://twoja-strona.com"
            value={form.url}
            onChange={(val) => setForm({ ...form, url: val })}
          />
          <CustomTextarea
            label="Wiadomość"
            placeholder="Opisz swój problem..."
            value={form.message}
            onChange={(val) => setForm({ ...form, message: val })}
          />
          <CustomCheckbox
            class="text-[10px] lg:text-[12px]"
            value={form.agreements}
            onChange={(val) => setForm({ ...form, agreements: val })}
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i promocyjnych przez Askay, ul.Malińska 1, 47-320 Gogolin, NIP: 1990126716. Wyrażam zgodę na przetwarzanie moich danych osobowych przez likepage.eu reprezentowany przez: w celu i w zakresie niezbędnym do realizacji obsługi niniejszego zgłoszenia. Zapoznałem się z treścią informacji o sposobie przetwarzania moich danych osobowych udostępnionych pod domeną likepage.eu/rodo"
          />
        </div>

        {errorMessage && (
          <div className="text-red-500 text-[14px] font-extrabold">
            <>{errorMessage}</>
          </div>
        )}
        <Button
          label="Wyślij wiadomość"
          class="self-start mt-2"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}

export default ContactCard;
