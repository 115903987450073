function SocialIcons() {
  const svgClass =
    'cursor-pointer fill-primary hover:fill-default transition-all duration-300';
  const pathClass = 'fill-inherit';

  return (
    <div className="flex flex-row justify-center items-center gap-4">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClass}
        onClick={() =>
          window.open(
            'https://www.facebook.com/profile.php?id=61557942004377',
            '_blank',
          )
        }
      >
        <path
          d="M10 0.0800781C4.5 0.0800781 0 4.57008 0 10.1001C0 15.1001 3.66 19.2501 8.44 20.0001V13.0001H5.9V10.1001H8.44V7.89008C8.44 5.38008 9.93 4.00008 12.22 4.00008C13.31 4.00008 14.45 4.19008 14.45 4.19008V6.66008H13.19C11.95 6.66008 11.56 7.43008 11.56 8.22008V10.1001H14.34L13.89 13.0001H11.56V20.0001C13.9164 19.6279 16.0622 18.4256 17.6099 16.6101C19.1576 14.7947 20.0054 12.4857 20 10.1001C20 4.57008 15.5 0.0800781 10 0.0800781Z"
          className={pathClass}
        />
      </svg>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClass}
        onClick={() =>
          window.open('https://api.whatsapp.com/send?phone=797897344', '_blank')
        }
      >
        <path
          d="M9.99 0C4.53 0 0.0800002 4.45 0.0800002 9.91C0.0800002 11.66 0.54 13.36 1.4 14.86L0 20L5.25 18.62C6.7 19.41 8.33 19.83 9.99 19.83C15.45 19.83 19.9 15.38 19.9 9.92C19.9 7.27 18.87 4.78 17 2.91C15.13 1.03 12.64 0 9.99 0ZM10 1.67C12.2 1.67 14.26 2.53 15.82 4.09C17.37 5.65 18.23 7.72 18.23 9.92C18.23 14.46 14.53 18.15 9.99 18.15C8.51 18.15 7.06 17.76 5.8 17L5.5 16.83L2.38 17.65L3.21 14.61L3.01 14.29C2.19 13 1.75 11.47 1.75 9.91C1.76 5.37 5.45 1.67 10 1.67ZM6.48 5.33C6.32 5.33 6.05 5.39 5.82 5.64C5.6 5.89 4.95 6.5 4.95 7.71C4.95 8.93 5.84 10.1 5.95 10.27C6.09 10.44 7.71 12.94 10.2 14C10.79 14.27 11.25 14.42 11.61 14.53C12.2 14.72 12.74 14.69 13.17 14.63C13.65 14.56 14.63 14.03 14.84 13.45C15.05 12.87 15.05 12.38 14.99 12.27C14.92 12.17 14.76 12.11 14.51 12C14.26 11.86 13.04 11.26 12.82 11.18C12.59 11.1 12.45 11.06 12.26 11.3C12.1 11.55 11.62 12.11 11.48 12.27C11.33 12.44 11.19 12.46 10.95 12.34C10.69 12.21 9.89 11.95 8.95 11.11C8.21 10.45 7.72 9.64 7.57 9.39C7.45 9.15 7.56 9 7.68 8.89C7.79 8.78 7.95 8.6 8.05 8.45C8.18 8.31 8.22 8.2 8.3 8.04C8.38 7.87 8.34 7.73 8.28 7.61C8.22 7.5 7.72 6.26 7.51 5.77C7.31 5.29 7.11 5.35 6.95 5.34C6.81 5.34 6.65 5.33 6.48 5.33Z"
          className={pathClass}
        />
      </svg>
      {/* <svg
        width="20"
        height="20"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClass}
      >
        <path
          d="M16.8999 0C17.4303 0 17.939 0.210714 18.3141 0.585786C18.6892 0.960859 18.8999 1.46957 18.8999 2V16C18.8999 16.5304 18.6892 17.0391 18.3141 17.4142C17.939 17.7893 17.4303 18 16.8999 18H2.8999C2.36947 18 1.86076 17.7893 1.48569 17.4142C1.11062 17.0391 0.899902 16.5304 0.899902 16V2C0.899902 1.46957 1.11062 0.960859 1.48569 0.585786C1.86076 0.210714 2.36947 0 2.8999 0H16.8999ZM16.3999 15.5V10.2C16.3999 9.33539 16.0564 8.5062 15.4451 7.89483C14.8337 7.28346 14.0045 6.94 13.1399 6.94C12.2899 6.94 11.2999 7.46 10.8199 8.24V7.13H8.0299V15.5H10.8199V10.57C10.8199 9.8 11.4399 9.17 12.2099 9.17C12.5812 9.17 12.9373 9.3175 13.1999 9.58005C13.4624 9.8426 13.6099 10.1987 13.6099 10.57V15.5H16.3999ZM4.7799 5.56C5.22547 5.56 5.65278 5.383 5.96784 5.06794C6.2829 4.75288 6.4599 4.32556 6.4599 3.88C6.4599 2.95 5.7099 2.19 4.7799 2.19C4.33169 2.19 3.90183 2.36805 3.58489 2.68499C3.26796 3.00193 3.0899 3.43178 3.0899 3.88C3.0899 4.81 3.8499 5.56 4.7799 5.56ZM6.1699 15.5V7.13H3.3999V15.5H6.1699Z"
          className={pathClass}
        />
      </svg> */}
    </div>
  );
}

export default SocialIcons;
