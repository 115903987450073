import { defaultStyles } from '@/helper/defaultStyles';
import axios from 'axios';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import Button from './Button';
import CustomCheckbox from './form/CustomCheckbox';
import CustomTextInput from './form/CustomTextInput';
import CustomTextarea from './form/CustomTextarea';
import SocialIcons from './icon/SocialIcons';

const listItemClass =
  'text-[14px] text-default cursor-pointer hover:text-primary transition-all duration-300';

function scrollRedirect(itemId: string) {
  if (window.location.pathname == '/') {
    document.getElementById(itemId)!.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.location.assign('/');
  }
}

function redirect(route: string) {
  window.location.assign(route);
}

const likepageList = {
  title: 'likepage',
  items: [
    <div className={listItemClass} onClick={() => redirect('/faq')}>
      FAQ
    </div>,
    <div className={listItemClass} onClick={() => redirect('/privacy')}>
      Polityka prywatności
    </div>,
    <div className={listItemClass} onClick={() => redirect('/rodo')}>
      RODO
    </div>,
    <div className={listItemClass} onClick={() => redirect('/terms')}>
      Regulamin
    </div>,
  ],
};

const aboutList = {
  title: 'o nas',
  items: [
    <div className={listItemClass} onClick={() => scrollRedirect('about')}>
      O nas
    </div>,
    <div className={listItemClass} onClick={() => scrollRedirect('service')}>
      Usługi
    </div>,
    <div className={listItemClass} onClick={() => scrollRedirect('portfolio')}>
      Portfolio
    </div>,
    <div className={listItemClass} onClick={() => scrollRedirect('packages')}>
      Pakiety
    </div>,
  ],
};

const contactList = {
  title: 'skontaktuj się',
  items: [
    <div
      className={listItemClass}
      onClick={() => (window.location.href = 'tel:+48797897344')}
    >
      +48 797 897 344
    </div>,
    <div
      className={listItemClass}
      onClick={() => (window.location.href = 'mailto:hello@likepage.eu')}
    >
      hello@likepage.eu
    </div>,
  ],
};

function ListItem(props: { title: string; items: JSX.Element[] }) {
  return (
    <div className="flex flex-col gap-4" key={props.title}>
      <div className="font-bold text-[#0057FF] uppercase text-[12px] tracking-[0.3em]">
        {props.title}
      </div>
      <div className="flex flex-col gap-2 items-start">{...props.items}</div>
    </div>
  );
}

const Footer: FunctionComponent = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form, setForm] = useState({
    email: '',
    name: '',
    message: '',
    agreements: false,
  });

  const onSubmit = () => {
    if (
      form.email == '' ||
      form.name == '' ||
      form.message == '' ||
      form.agreements == false
    ) {
      setErrorMessage('Uzupełnij wszystkie pola formularza');
      return;
    } else {
      setErrorMessage(null);
    }

    toast.promise(axios.post('https://api.likepage.eu/contact/', form), {
      pending: 'Wysyłanie wiadomości...',
      success: 'Wysłano wiadomość',
      error: 'Wystąpił błąd przy wysyłaniu',
    });
  };

  return (
    <footer
      className={`${defaultStyles.paddingBig} flex flex-col lg:flex-row justify-center items-center gap-8 py-12`}
    >
      <div className="lg:w-[40%] bg-[#0A1722] p-8 rounded-[66px] flex flex-col gap-8 items-start">
        <div className="font-extrabold text-[25px] lg:text-[55px] text-default">
          Skontaktuj się z nami
        </div>
        <div className="flex flex-col gap-4">
          <CustomTextInput
            label="Adres E-mail"
            placeholder="mail@mail.pl"
            value={form.email}
            onChange={(val) => setForm({ ...form, email: val })}
          />
          <CustomTextInput
            label="Imię i nazwisko"
            placeholder="Jan Kowalski"
            value={form.name}
            onChange={(val) => setForm({ ...form, name: val })}
          />
          <CustomTextarea
            label="Wiadomość"
            placeholder="Treść twojej wiadomości..."
            value={form.message}
            onChange={(val) => setForm({ ...form, message: val })}
          />
          <CustomCheckbox
            class="text-[10px] lg:text-[12px]"
            value={form.agreements}
            onChange={(val) => setForm({ ...form, agreements: val })}
            label="Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i promocyjnych przez Askay, ul.Malińska 1, 47-320 Gogolin, NIP: 1990126716. Wyrażam zgodę na przetwarzanie moich danych osobowych przez likepage.eu reprezentowany przez: w celu i w zakresie niezbędnym do realizacji obsługi niniejszego zgłoszenia. Zapoznałem się z treścią informacji o sposobie przetwarzania moich danych osobowych udostępnionych pod domeną likepage.eu/rodo"
          />
          <Button
            label="Wyślij wiadomość"
            onClick={onSubmit}
            class="w-[209px]"
          />
          {errorMessage && (
            <div className="text-red-500 text-[14px] font-extrabold">
              <>{errorMessage}</>
            </div>
          )}
        </div>
      </div>
      <div className="lg:w-[40%] flex flex-col h-full">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-12">
          <ListItem {...likepageList} key="likepageList" />
          <ListItem {...aboutList} key="aboutList" />
          <ListItem {...contactList} key="contactList" />
        </div>
        <div className="flex flex-row items-end justify-between mt-24">
          <SocialIcons />
          <div className="text-[10px]">
            Likepage {new Date().getFullYear()} ©
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
