function CustomTextarea(props: {
  placeholder: string;
  label: string;
  onChange: (text: string) => void;
  value: string;
  class?: string;
}) {
  return (
    <div className={`w-full flex flex-col gap-2 ${props.class}`}>
      <label className="text-[16px] font-bold text-default">
        {props.label}
      </label>
      <textarea
        className="text-[14px] bg-[#112333] text-default text-left border border-solid border-[#2b4258] outline-none p-2 lg:p-4 rounded-[45px]"
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.currentTarget.value)}
        aria-label={props.label}
      />
    </div>
  );
}

export default CustomTextarea;
