import { useState } from 'react';

import Button from './Button';
import ContactCard from './contact/ContactCard';
import BurgerIcon from './icon/BurgerIcon';

const navbarItems = [
  {
    title: 'Portfolio',
    route: '/portfolio',
  },
  {
    title: 'Pakiety',
    route: '/packages',
  },
  {
    title: 'Integracje',
    route: '/integrations',
  },
];

const navItemClass =
  'cursor-pointer p-4 rounded-[67px] text-lighterDark hover:text-default bg-transparent hover:bg-primary transition-all duration-300 no-underline';

function ContactItem(props: {
  img: string;
  text: string;
  onClick: () => void;
}) {
  return (
    <div className="flex flex-row gap-4 items-center">
      <img alt="icon" width="32" height="32" src={props.img} />
      <div
        className="text-default hover:text-primary cursor-pointer transition-all duration-300"
        onClick={props.onClick}
      >
        {props.text}
      </div>
    </div>
  );
}

const contactData = [
  {
    img: '/icon/mail.svg',
    text: 'hello@likepage.eu',
    onClick: () => (window.location.href = 'mailto:hello@likepage.eu'),
  },
  {
    img: '/icon/phone.svg',
    text: '797 897 344',
    onClick: () => (window.location.href = 'tel:+48797897344'),
  },
];

function Navbar() {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <nav className="flex flex-col p-4 2xl:p-2 mt-4">
        <div className="hidden w-full 2xl:flex flex-row items-center justify-center">
          <div className="w-full flex flex-row justify-between items-center px-4">
            <img
              className="w-[266px] h-[64px] cursor-pointer"
              src="/logo.webp"
              alt="logo"
              loading="lazy"
              onClick={() => window.location.assign('/')}
            />
            <div className="flex flex-row gap-4">
              {navbarItems.map((item, index) => (
                <div
                  key={index}
                  className={navItemClass}
                  onClick={() => window.location.assign(item.route)}
                >
                  {item.title}
                </div>
              ))}
              <Button
                label="Darmowa konsultacja"
                class="w-[209px]"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </div>
        </div>
        <div className="flex 2xl:hidden flex-row justify-between items-center px-4">
          <img
            className="w-[180px] h-[43px] cursor-pointer"
            src="/logo.webp"
            onClick={() => window.location.assign('/')}
            alt="logo"
            loading="lazy"
          />
          <BurgerIcon
            isToggled={isBurgerToggled}
            onClick={() => setIsBurgerToggled(!isBurgerToggled)}
          />
        </div>
        <div
          className={`${
            isBurgerToggled ? 'flex' : 'hidden'
          } flex-col justify-start items-start gap-4`}
        >
          <div className="py-4 flex flex-col justify-center items-start">
            {navbarItems.map((item, index) => (
              <div
                key={index}
                className={navItemClass}
                onClick={() => window.location.assign(item.route)}
              >
                {item.title}
              </div>
            ))}
            <Button
              label="Darmowa konsultacja"
              class="w-[209px] mt-2"
              onClick={() => setIsModalOpen(true)}
            />
            <div className="flex flex-col gap-2 mt-4">
              {contactData.map((item, index) => (
                <ContactItem {...item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </nav>
      {isModalOpen && (
        <div className="fixed w-[100%] h-[100%] top-0 left-0 backdrop-blur-lg z-40 flex flex-col justify-center items-center">
          <div className="w-[90%] lg:w-[70%] lg:h-[70%] z-50 flex flex-col justify-center items-center">
            <ContactCard onClick={() => setIsModalOpen(false)} />
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
