function BurgerIcon(props: { isToggled: boolean; onClick: () => void }) {
  const lineClass =
    'h-[4px] bg-primary absolute w-full origin-center transition-all duration-300 ease-in-out';

  return (
    <div
      onClick={props.onClick}
      className={`w-12 h-12 relative cursor-pointer ${
        props.isToggled ? 'toggled' : ''
      }`}
    >
      <div className={`${lineClass} top-[20%] line1`} />
      <div className={`${lineClass} top-[45%] line2`} />
      <div className={`${lineClass} top-[70%] line3`} />
    </div>
  );
}

export default BurgerIcon;
